/* Root and overall App */

:root {
    /* Color Scheme */
    --highlight_middle:#f8ffe5 ;
    --middle:#3083DC;
    --middle_light: #5d9ee4;
    --light: #7db1e9;
    --light_opposite: #E9907C;
    --highlight_text:#205484;
    --back: white;
    --highlight: #2ebfa5;
    --second_highlight: #1eb300;
    --front: white;
    --cal_border: lightgrey; 
    
    --banner:#83c5be;
    --slot_background:#EFF4F5;

    --text: #006d77;
    --header:#7ad1e5;
    --background:#f0f2f3;
    --drop_shadow:#76b0b7;
    --tie:#ffde75;
  }

.App {
    background-color: var(--background);
    min-height: 100vh;
}


/* Banner */ 

#banner {
    min-width:100%;
    display: flex;
    flex-wrap: wrap;
    background-color:var(--banner);
    align-content: center;
    justify-content: space-around;
}

.robotLogo {
    height: 75px;

}

.g_id_signin {
  align-self: center;
}

.logoutBtn {
    max-width: 150px;
    min-width: 130px;
    padding: 10px;
    align-self: center;
}


/* App.js or Room */

.roomMain {
    background-color: var(--background);
}

#roomName {
    font-size: xx-large;
    text-align: center;
    color:var(--text); 
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 5px;
}

#urlLink {
    font-size: x-large;
    text-align: center;
    cursor:copy;
    position: relative;

}

.popup {
    visibility: hidden;
    background-color: #555;
    color:#fff;
    position: absolute;
    z-index: 1;
    bottom: 90%;
    left: 45%;
    border-radius: 5px;
    padding: 1px 4px 2px 4px;
}

/* Popup arrow */
.popup::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  /* Toggle this class - hide and show the popup */
.show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
    
  }

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
    from {opacity: 0;} 
    to {opacity: 1;}
  }
  
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity:1 ;}
  }

  

/* Panels */

    /* Custom Panel */ 

#customPanel {
    margin-left: 5vw;
    margin-right: 5vw;
}

.customTimeSlot {
    /*background-color: #ECF9F3;
    border-style:solid;
    border-color: var(--slot_background);
    border-radius: 5px;*/
    padding-bottom: 10px;
}

.timeName {
    font-size: large;
    text-align: center;
    border-bottom: solid var(--drop_shadow);
    margin-bottom:0px;
    border-width: 1px;
}

#preferenceInput{
    display: flex;
    direction: row;
    width: 100%;

  }

  #preferenceInput input{
    width: 0; height: 0; position: absolute; left: -9999px;
  
  }

  #preferenceInput input+label{
    width:100%;
    text-align: center;
  }

  #preferenceInput > label {
    float:right;
    background-color:var(--slot_background);
    border-style: solid;
    border-color: var(--drop_shadow);
    border-width: 1px;
  }

  #preferenceInput label:first-of-type{
    /*border-style: none;*/
    
  }

  #preferenceInput input + label:hover{
    background-color: #C5ECFF;
  }

  #preferenceInput .selectedTime + label{
    background-color: lightblue;
  }



      /*UserPanel*/

.userpanel-title{
    text-align: center;
    margin-top: 25px;
    border-top-style: solid;
    width: 30%;
    margin-left:auto;
    margin-right:auto;
    border-color: var(--drop_shadow);
}

#userPanel{
    margin:auto;
    width:100%;
    display:inline-flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow:auto;
    max-height:50vh;
}

.userDiv { 
    width:40%;
    margin-left: 5%;
  }

    /* Setting Panel */ 

#settingPanel {
    text-align: center;
}

#calcButton, #deleteButton {
    background-color: white;
    color:black;
    border: 1px solid #CCC;
    box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);   
    width:40%;
    float: left;
    margin-left:8%;
}

 /* Name Panel */

 .namepanel-title {
  text-align: center;
    margin-top: 25px;
    border-top-style: solid;
    width: 30%;
    margin-left:auto;
    margin-right:auto;
    border-color: var(--drop_shadow);
 }

#namePanel {
  margin:auto;
  width:100%;
  display:inline-flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  overflow:auto;
  max-height:50vh;
}

#calcDiv {
  z-index: 2;
  position:fixed;
  left:5vw;
  top:10vh;
  width:90vw;
  height:80vh;
  background-color:white;
  border-radius: 25px;
  border-radius: 50px 50px 20px 20px;
  box-shadow: 2px 2px 1px var(--drop_shadow) ;
  overflow: auto;
}

.calcHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calcHeader h1 {
  margin-left: 5vw;
  margin-right: 2vw;
}

#closeCalcButton {
  margin-right: 5vw;
  font-size: xx-large;
  cursor: pointer;
}

#rotatedLabels {
  display: flex;
  justify-content: space-evenly;
}

.answer {
  border-bottom: solid;
  width:90%;
  margin: auto;
  border-color: var(--drop_shadow);
  border-radius: 10px;
  margin-bottom: 20px;
}

.answer h4 {
  margin: 0px;
  font-size: x-large;  
}
.participantFlex {
  display:flex;
}

.coming {
  background-color: var(--light);
  width:50%;
  display:flex;
  justify-content: space-evenly;
  border-radius: 20px 0px 0px 10px;
}

.notComing {
  background-color: var(--light_opposite);
  width: 50%;
  display:flex;
  justify-content: space-evenly;
  border-radius: 0px 20px 10px 0px;
}

/* LoginScreen */

.welcomeText {
  text-align: center;
  margin-left: 3vw;
  margin-right:3vw;
  font-size: large;
  padding-bottom: 80vh;
}

/* LoginRedirect */ 

.LoginRedirect{
  background: var(--background);
  height:100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoginRedirect h1 {
  margin-bottom: 5vh;
}


/* RoomScreen */ 

.greeting {
    text-align: center;
}
.formButtonAligner {
  display: flex;
  justify-content: center;
}

.formButton {
    font-size: x-large;
    width:200px;
    background-color: white;
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 20px;
    color:var(--text);
    padding: 15px;
    left:50%;

}

.roomFlex{
  display:flex;
  flex-wrap: wrap;
  margin: 10px;
  justify-content: space-evenly;
  min-height: 90vh;
  align-items: flex-start;

}

.roomTile {
  color:white;
  width:min(80%, 500px);

  border-radius: 15px;
  margin-bottom: 20px;
  box-shadow: 2px 2px 1px var(--drop_shadow) ;
  align-self: center;
  background-color: var(--header);
  transition: 2s;
  position: relative;
}

.roomTile h3 {
  font-weight: 400;
  padding-left: 20px;
  margin: 10px;
}

.bowtie{
  transform:rotate(30deg);
  width: 15%;
  position: absolute;
  right:-5%;
  top:-15%;
  max-width: 80px;
}

@keyframes fadeOut {
  from {opacity: 100%;}
  to {opacity:0%}
}

.fadeOut{
  animation:fadeOut;
  animation-duration: 3s;
  animation-iteration-count: 1;
  visibility: visible;
}


  /* Room Creation */

.formDiv {
  background-color: var(--drop_shadow);
  height: calc(100vh - 75px);
  width:100%;
  position: absolute;
  z-index: 1;
  top:75px;
}

.formBanner {
  background-color: var(--middle_light);
  display:flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-style: solid;
  border-color: var(--highlight_text);
}

.formBanner h3 {
  margin-left: 5vw;
  margin-right:10vw;
}

#roomForm {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
}

#roomFormHeader {
  display: flex;
  column-gap: 20px;
  margin-top: 10px;
}
#eventNameLabel{
  justify-self: left;

}

#eventName{
  justify-self: right;
  width:150px
}

.customFormHeader {
  display: flex;
  column-gap: 20px;

}

#customTimeLabel{
  justify-self: left;
}

#colNumber {
  min-width: 75px;
  margin-right: 10vw;
  
  
}

.formCusom {

}

.formCustom h1 {


}

.generatedFlex{

}

.formCustom input {

}

/* Footer */



footer h4{
    text-align: center;
    margin-top: 20vh;
  }



  /* Responsive CSS */ 
